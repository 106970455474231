<template>
  <div>
    <b-form class="auth-login-form mt-2">
      <b-form-group label="Contraseña" label-for="password">
        <b-form-input id="password"
          v-model="resetForm.password"
          name="password"
          type="password"
          placeholder="Contraseña"
          :state="passwordErrors.length > 0 ? false:null"
          @input="$v.resetForm.password.$touch()"/>
        <small class="text-danger">{{ passwordErrors[0] }}</small>
      </b-form-group>
      <b-form-group label="Repetir Contraseña" label-for="repeatPassword">
        <b-form-input id="repeatPassword"
          v-model="resetForm.repeatPassword"
          name="repeatPassword"
          type="password"
          placeholder="Repetir Contraseña"
          :state="confirmPasswordErrors.length > 0 ? false:null"
          @input="$v.resetForm.repeatPassword.$touch()"/>
        <small class="text-danger">{{ confirmPasswordErrors[0] }}</small>
      </b-form-group>
      <div>
        <b-overlay
          no-wrap
          :show="overlayForm"
          opacity="0.1"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="_onHidden()"/>
        <b-button
          block
          ref="button"
          :disabled="overlayForm"
          variant="primary"
          @click.prevent="_resetPassword()">
          <feather-icon
            icon="SaveIcon"
            class="mr-50"/>
          <span class="align-middle">
            Cambiar Contraseña
          </span>
        </b-button>
      </div>
    </b-form>
    <p class="text-center mt-2">
      <b-link :to="{name:'login'}">
        <feather-icon icon="ChevronLeftIcon" />
        Volver al inicio de sesión
      </b-link>
    </p>
  </div>
</template>

<script>
  import {mapActions,mapState} from 'vuex'
  import {required,minLength,sameAs,email} from 'vuelidate/lib/validators'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default{
    name: 'ResetPasswordForm',
    data() {
      return {
        overlayForm: false,
        resetForm:{
          token: '',
          email: '',
          password: '',
          repeatPassword: '',
        }
      }
    },
    validations: {
      resetForm: {
        token: {
          required,
        },
        password: {
          required,
          minLength: minLength(4)
        },
        repeatPassword: {
          sameAsPassword: sameAs('password')
        }
      }
    },
    beforeMount() {
      this.resetForm.token = this.$route.params.token;
      this.resetForm.email = this.$route.params.email;
    },
    computed: {
      ...mapState('password',['resetPassword']),
      passwordErrors () {
        const errors = []
        if (!this.$v.resetForm.password.$dirty) return errors
        !this.$v.resetForm.password.required && errors.push('La contraseña es obligatorio')
        !this.$v.resetForm.password.minLength && errors.push('La contraseña debe tener mínimo 4 caracteres')
        return errors
      },
      confirmPasswordErrors () {
        const errors = []
        if (!this.$v.resetForm.repeatPassword.$dirty) return errors
        !this.$v.resetForm.repeatPassword.sameAsPassword && errors.push('Las contraseñas no coinciden')
        return errors
      },
    },
    methods: {
      ...mapActions('password',['sendResetPassword']),
      async _resetPassword() {
        this.$v.resetForm.$touch()
        if (!this.$v.resetForm.$invalid) {
          this.overlayForm = true
          await this.sendResetPassword(this.resetForm)
          .then(res =>{
            this.$router.push({path: '/'}).catch(err => {console.info(err.message)})
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Se ha cambiado la contraseña con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No se pudo cambiar la contraseña',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          });
        }
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>