<template>
  <div class="auth-wrapper auth-v2" id="page-reset">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2"/>
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Restablecer Contraseña
          </b-card-title>
          <b-card-text class="mb-2">
            Ingrese su nueva contraseña
          </b-card-text>
          <reset-password-form></reset-password-form>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import store from '@/store/index'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import ResetPasswordForm from './components/ResetPasswordForm'

  export default{
    name: 'ResetPasswordView',
    components: {VuexyLogo,ResetPasswordForm},
    data() {
      return {
        sideImg: require('@/assets/images/svg/reset-password.svg'),
      }
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
          return this.sideImg
        }
        return this.sideImg
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>